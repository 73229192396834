@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

@import './assets/css/animate.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  --color-primary: green;
  --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

.belender-toggle-group button[aria-pressed="true"] {
  background: linear-gradient(0deg, #385898 0%, #4a6fb9 10%, #4a6fb9,#4a6fb9 90%, #385898 100% );
  color: #ffffff;
}

mat-checkbox .mat-checkbox-label{
  white-space: pre-wrap !important;
}

.color-info svg{
  fill: #4886D3 !important;
}

.color-success svg{
  fill: #53ACA0 !important;
}

.color-warning svg{
  fill: #DB8A4D !important;
}

.color-danger svg{
  fill: #C3505E !important;
}

.color-accent svg{
  fill: #6358EC !important;
}

.bg-orange{
  background: #c2410c;
}

.text-orange{
  color: #c2410c;
}

.bg-orange-light{
  background: #c2400c19;
}

.bg-gray{
  background: #8d8d8d;
}

.text-gray{
  color: #ccc;
}

.bg-gray-light{
  background: rgba(255, 255, 255, 0.098);
}

.vex-style-default .bg-gray-light{
  background: rgba(0, 0, 0, 0.06);
}

.timeline-wrapper{
  padding-left: 11px;
}

.timeline-item{
  display: flex;
  align-items: flex-end;
  padding-top: 20px;
  border-left: 1px solid #ffffff2b;
}

.timeline-item mat-icon{
  margin: 0 10px 0px -10px;
  background: #1a202e;
}

.vex-style-default .timeline-wrapper .timeline-item mat-icon{
  background: #fff;
}

.vex-style-default .timeline-wrapper .timeline-item{
  border-left: 1px solid #0000001e;
}

.delay-0{
  animation-delay: 0ms;
}

.delay-1{
  animation-delay: 100ms;
}

.delay-2{
  animation-delay: 200ms;
}

.delay-3{
  animation-delay: 300ms;
}

.delay-4{
  animation-delay: 400ms;
}

.delay-5{
  animation-delay: 500ms;
}

.delay-6{
  animation-delay: 600ms;
}

.delay-7{
  animation-delay: 700ms;
}

.delay-8{
  animation-delay: 800ms;
}

.delay-9{
  animation-delay: 900ms;
}

.delay-10{
  animation-delay: 1000ms;
}

.delay-11{
  animation-delay: 1100ms;
}

.delay-12{
  animation-delay: 1200ms;
}

.delay-13{
  animation-delay: 1300ms;
}

.delay-14{
  animation-delay: 1400ms;
}

.delay-15{
  animation-delay: 1500ms;
}

.delay-16{
  animation-delay: 1600ms;
}

.delay-17{
  animation-delay: 1700ms;
}

.delay-18{
  animation-delay: 1800ms;
}

.delay-19{
  animation-delay: 1900ms;
}

.delay-20{
  animation-delay: 2000ms;
}

.belender-tooltip{
  background-color: #0b0d11 !important;
  text-align: center !important;
  box-shadow: 0 5px 12px #0000001f;
}

.mat-snack-bar-container.error{
  background: #f44336 !important;
  color: white !important;
}

.mat-snack-bar-container.error button{
  color: white !important;
}

.mat-snack-bar-container.info{
  background: #008bff !important;
  color: white !important;
}

.mat-snack-bar-container.info button{
  color: white !important;
}

.mat-snack-bar-container.success{
  background: #148c5a !important;
  color: white !important;
}

.mat-snack-bar-container.success button{
  color: white !important;
}

 .mat-tab-body, .mat-tab-body-content, .mat-tab-body-wrapper{
  overflow: visible !important;
}

.ql-align-center{
  text-align: center;
}

.ql-align-left{
  text-align: left;
}

.ql-align-right{
  text-align: right;
}

.widget-belender h1{
  font-size: 26px !important;
}

.widget-belender h2{
  font-size: 19.5px !important;
}

.widget-belender h3{
  font-size: 15.2px !important;
}

.widget-belender h4{
  font-size: 13px !important;
}

.widget-belender h5{
  font-size: 10.7px !important;
}

.widget-belender h6{
  font-size: 8.6px !important;
}

.widget-belender p{
  font-size: 14px !important;
}

div.ql-snow .ql-editor{
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  min-height: 226px;
  border-radius: 0 0 8px 8px;
}

span.mat-checkbox-label{
  font-size: 12px !important;
}

@media (max-width: 600px) {
  .content-widget{
      padding: 0 !important;
      min-height: 100vh;
  }

  .content-widget.content-layout{
    padding-top: 0 !important;
  }

  .content-widget .card{
      box-shadow: none !important;
  }
  
}

.widget-belender .fixed-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 426px;
}

.belender-widget-wrapper .card mat-form-field{
  max-width: 100% !important;
}

.widget-belender .mat-horizontal-stepper-header-container{
  background: #00000008;
}

.widget-belender  .mat-horizontal-stepper-content form  {
  height: 100% !important;
  min-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.widget-belender .form-content{
  overflow: auto !important;
  max-height: calc(100vh - 158px);
}

.widget-boxed .form-content{
  max-height: 350px !important;
}

.widget-belender .actions{
  height: 60px;
}

.mat-horizontal-stepper-header-container{
  pointer-events: none !important;
}

.question-wrapper{
  min-height: 34px;
}

.transparent-content .inputs-row.not-editing{
  opacity: 0 !important;
  height: 1px;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.dropdown-funders > .mat-form-field-wrapper{
  margin: 0 !important;
  padding: 0 !important;
}


/* sleleton elements */
.skeleton {
  background: linear-gradient(
    90deg,
    hsl(210, 15%, 88%),
    hsl(210, 15%, 95%),
    hsl(210, 15%, 88%)
  );
  background-size: 200%;
  animation: loader 1s infinite reverse;
}
h2.skeleton {
  width: 50%;
  height: 3em;
}
small.skeleton {
  height: 1rem;
  width: 4rem;
}
p.skeleton {
  height: 1em;
  width: 80%;
}

/* skeleton animation */
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

.table-hidden-date{
  width: 0px !important;
  max-width: 0px !important;
  overflow: hidden !important;
  display: flex !important;
  height: 0px !important;
  opacity: 0 !important;
}

.no-padding-bottom .mat-form-field-wrapper{
  padding-bottom: 0 !important;
}

.icon-refreshing{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: transparent;
  border-left: 2px solid #4caf50;
  border-right: 2px solid #4caf50;
  border-bottom: 2px solid #4caf50;
  border-top: 2px solid transparent;
  animation: spin-icon 1s linear infinite;
  display: inline-flex;
}

@keyframes spin-icon{
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}

.new-request mat-step-header{
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: flex-end;
  gap: 3px;
}

.new-request .mat-stepper-horizontal-line{
  border-top-width: 2px;
  margin: 0 -44px;
}


.mat-step-header .mat-step-icon-selected, .mat-step-icon-state-edit{
  background: #2196F3 !important;
}

.mat-step-header .mat-step-icon{
  margin: 0 !important;
}

.mat-step-header .mat-step-label{
  text-align: center !important;
}

.mat-step-icon-selected{
  margin: 0;
  outline: 1px solid #2196F3;
  outline-offset: 2px;
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false]{
  background: transparent !important;
}

.w-search .mat-select-panel{
  background: red !important;
  padding-top: 100px !important;
}